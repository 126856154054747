@media only screen and (max-width: 1800px) {
  .head-menus ul:last-child {
    gap: 40px;
  }
  .regiter-log h2 {
    font-size: 26px;
  }
  .register-pg section.sec-1-contact .row {
    padding: 30px 20px;
  }
  .regiter-log p {
    font-size: 18px;
  }
  .login-pg span.sp-ban-2 {
    top: 41px;
    right: 0;
  }

  .login-pg span.sp-ban-1 {
    bottom: 45px;
    left: 0;
  }
  .register-pg .sec-contact-title h2 {
    font-size: 30px;
  }
  .head-menus ul:first-child {
    gap: 80px;
  }
  .login-pg.register-pg section.sec-1-contact {
    padding-top: 10px;
  }
  .head-menus ul li a {
    font-size: 16px;
  }

  .head-menus ul:last-child li:last-child a {
    padding: 0 0;
    width: 120px;
    height: 40px;
  }

  ul.child-ul-2 li a img {
    width: 72%;
  }
  span.sp-ban-1 img {
    width: 80%;
  }
  span.sp-ban-2 img {
    width: 80%;
  }
  span.sp-ban-2 {
    text-align: end;
  }
  span.sp-ban-world img {
    width: 90%;
  }

  span.sp-ban-world {
    right: 14rem;
  }

  .banner-title h1 {
    font-size: 55px;
  }

  .banner-title p {
    font-size: 26px;
  }

  .col-lg-5.about-img img {
    width: 75%;
    height: 100%;
  }
  .col-lg-5.aboutMacedonia img {
    width: 75%;
    height: 100%;
  }
  .col-lg-5.aboutMacedonia {
    text-align: center;
    transform: translateX(10px);
  }
  .aobut-content h2 {
    font-size: 40px;
  }
  section.sec-1.hm .aobut-content {
    transform: translateX(-60px);
  }
  video {
    height: 110%;
  }
  .aobut-content p {
    font-size: 20px;
    width: 80%;
  }
  section.sec-2 h1 {
    font-size: 42px;
  }

  .howto-content h2 {
    font-size: 40px;
  }

  .howto-content p {
    font-size: 21px;
  }
  .rg-web {
    text-align: end;
  }

  .container-1600 {
    width: 86vw;
  }

  .copy-right-inner p {
    font-size: 11px;
    text-align: center;
  }

  .copy-right-inner a {
    font-size: 12px;
  }
  footer {
    padding: 80px 0px;
  }

  .copy-right {
    padding: 12px 0px;
    padding: 12px 0px;
  }

  .copy-right .container-1600 {
    align-items: center;
  }

  .col-lg-2.get-touch h2 {
    font-size: 40px;
  }

  span.links-social a {
    font-size: 15px;
  }

  span.links-social a img {
    width: 20px;
    height: 23px;
  }
  footer .col-lg-8 img {
    width: 27%;
  }
  footer p {
    font-size: 23px;
  }

  .col-lg-2.social h2 {
    font-size: 28px;
  }

  .col-lg-2.social ul li a {
    font-size: 18px;
  }

  .col-lg-2.social h2 {
    font-size: 27px;
  }

  ul.icons-soc li a img {
    width: 90%;
  }
  .meanbox {
    gap: 40px;
  }

  .ourbox p {
    font-size: 16px;
  }

  .ourbox h2 {
    font-size: 20px;
  }

  /* Dashboard responsive */
  .dash-title h1 {
    font-size: 60px;
  }

  .Dashboard-left-bar {
    width: 360px;
    margin-left: 40px;
  }

  .Dashboard-righ-side {
    width: calc(100% - 400px);
  }

  .Dash-menus ul {
    gap: 45px;
  }
  .enter-form h1 {
    font-size: 47px;
    padding: 10px 0;
  }

  .enter-form h3 {
    font-size: 30px;
  }

  .enter-form h4 {
    font-size: 30px;
  }

  .enter-form.admin-dv {
    padding: 40px 60px 70px 60px;
  }

  .enter-form.admin-dv img {
    width: 74%;
  }

  .row.comments .row.notificationpg img {
    width: 75px;
  }
}

@media only screen and (max-width: 1600px) {
  .head-menus ul:last-child {
    gap: 40px;
  }
  .meanbox {
    gap: 40px;
  }

  .ourbox p {
    font-size: 16px;
  }

  .ourbox h2 {
    font-size: 20px;
  }
  .head-menus ul:first-child {
    gap: 80px;
  }
  .register-pg .sec-contact-title h2 {
    font-size: 30px;
  }
  .head-menus ul li a {
    font-size: 16px;
  }

  .head-menus ul:last-child li:last-child a {
    padding: 0 0;
    width: 120px;
    height: 40px;
  }

  ul.child-ul-2 li a img {
    width: 72%;
  }
  span.sp-ban-1 img {
    width: 80%;
  }
  span.sp-ban-2 img {
    width: 80%;
  }
  span.sp-ban-2 {
    text-align: end;
  }
  span.sp-ban-world img {
    width: 90%;
  }

  span.sp-ban-world {
    right: 14rem;
  }

  .banner-title h1 {
    font-size: 55px;
  }

  .banner-title p {
    font-size: 26px;
  }

  .col-lg-5.about-img img {
    width: 75%;
    height: 90%;
  }
  .col-lg-5.aboutMacedonia img {
    width: 75%;
    height: 90%;
  }
  .col-lg-5.aboutMacedonia {
    text-align: center;
    transform: translateX(10px);
  }
  .aobut-content h2 {
    font-size: 40px;
  }
  section.sec-1.hm .aobut-content {
    transform: translateX(-60px);
  }
  video {
    height: 110%;
  }
  .aobut-content p {
    font-size: 20px;
    width: 80%;
  }
  section.sec-2 h1 {
    font-size: 42px;
  }

  .howto-content h2 {
    font-size: 40px;
  }

  .howto-content p {
    font-size: 21px;
  }
  .rg-web {
    text-align: end;
  }

  .container-1600 {
    width: 86vw;
  }

  .copy-right-inner p {
    font-size: 11px;
    text-align: center;
  }

  .copy-right-inner a {
    font-size: 12px;
  }
  footer {
    padding: 80px 0px;
  }

  .copy-right {
    padding: 12px 0px;
    padding: 12px 0px;
  }

  .copy-right .container-1600 {
    align-items: center;
  }

  .col-lg-2.get-touch h2 {
    font-size: 40px;
  }

  span.links-social a {
    font-size: 15px;
  }

  span.links-social a img {
    width: 20px;
    height: 23px;
  }
  footer .col-lg-8 img {
    width: 27%;
  }
  footer p {
    font-size: 23px;
  }

  .col-lg-2.social h2 {
    font-size: 28px;
  }

  .col-lg-2.social ul li a {
    font-size: 18px;
  }

  .col-lg-2.social h2 {
    font-size: 27px;
  }

  ul.icons-soc li a img {
    width: 90%;
  }

  .row.let-know p {
    font-size: 22px;
  }

  .let-know img {
    width: 20%;
  }
  .container-1050 {
    width: 60vw;
  }
  .sec-contact-title h1 {
    font-size: 55px;
  }

  .sec-contact-title h2 {
    font-size: 40px;
  }

  .col-lg-4.social-icons h2 {
    font-size: 40px;
  }

  section.sec-1-contact input,
  label {
    height: 40px;
    font-size: 17px;
  }

  section.sec-1-contact textarea {
    height: 180px;
  }

  .form-btm a {
    height: 40px;
    font-size: 15px;
    width: 150px;
    margin-top: 20px;
  }
  .banner-contact h2 {
    font-size: 30px;
  }

  .banner-login h1 {
    font-size: 45px;
  }

  p.welcom {
    font-size: 17px;
    margin-top: 10px;
  }

  .banner-login .banner-btn a {
    width: 170px;
    font-size: 18px;
    height: 45px;
  }

  .forgerting span p {
    font-size: 16px;
  }

  .forgerting span input {
    height: 25px;
    width: 20px;
  }
  .regiter-log h2 {
    font-size: 26px;
  }

  .regiter-log p {
    font-size: 18px;
  }
  .register-pg .sec-contact-title h2 {
    font-size: 30px;
  }

  .row.under-row .banner-btn a {
    padding: 0 40px;
    font-size: 15px;
    height: 42px;
  }

  /* Dashbaord responisve */
  .dash-title h1 {
    font-size: 56px;
  }
  .dash-title {
    padding: 20px 3rem;
  }
  .Dash-logo img {
    width: 46%;
  }
  .img-upload {
    width: 110px;
    height: 110px;
  }

  .profile-upload span svg {
    font-size: 20px;
  }

  .profile-upload {
    margin-top: 20px;
  }

  .profile-upload p {
    font-size: 18px;
  }

  .Dash-menus ul li a {
    font-size: 17px;
  }

  .Dashboard-left-bar {
    width: 330px;
    margin-left: 40px;
  }

  .Dashboard-righ-side {
    width: calc(100% - 370px);
  }
  .Dash-menus {
    margin-bottom: 8rem;
    margin-top: 4rem;
  }

  .ul-btn .banner-btn a {
    font-size: 15px;
    padding: 0 40px;
    height: 47px;
  }

  .row.get-cert label.custom-file-upload.fas .img-upload img {
    width: 70px;
    height: 70px;
  }

  .row.get-cert label.custom-file-upload.fas {
    padding-top: 10px;
    height: 70%;
  }

  .enter-form label {
    font-size: 16px;
  }

  .row.get-cert label.custom-file-upload.fas p {
    margin: 0;
  }

  .enter-form .row.get-cert h2 {
    font-size: 25px;
  }

  .enter-form .row.get-cert .banner-btn a {
    /* font-size: 15px; */
    /* height: 40px; */
    font-size: 15px;
    padding: 0 50px;
    height: 47px;
  }
  .download-pdf img {
    width: 27px;
    height: 27px;
  }
  .row.notificationpg h2 {
    font-size: 18px;
  }

  .row.notificationpg p {
    font-size: 15px;
    width: 50%;
  }

  .row.notificationpg img {
    width: 86px;
  }
  table.simple_zebra {
    width: 83%;
    margin-top: 60px;
  }

  table.simple_zebra tbody tr td {
    font-size: 12px;
  }

  table.simple_zebra thead tr th {
    font-size: 14px;
  }

  .pagination {
    width: 85%;
  }
}

@media only screen and (max-width: 1440px) {
  .head-menus ul li a {
    font-size: 14px;
    padding: 6px 12px;
  }
  .banner-contact {
    width: 40%;
  }
  .head-menus ul:last-child li:last-child a {
    padding: 0 0;
    width: 110px;
    height: 38px;
  }
  .banner-title h1 {
    font-size: 40px;
  }
  span.sp-ban-world img {
    width: 78%;
  }
  span.sp-ban-world {
    right: 10rem;
    top: 1rem;
  }

  .banner-title p {
    font-size: 20px;
  }
  .banner-btn a {
    height: 40px;

    font-size: 13px;
    padding: 0 20px;
  }
  span.sp-ban-2 img {
    width: 68%;
  }
  span.sp-ban-1 img {
    width: 65%;
  }
  .aobut-content h2 {
    font-size: 35px;
  }
  .aobut-content p {
    font-size: 17px;
    width: 100%;
  }
  span.ALex img {
    width: 80%;
  }
  .col-lg-5.about-img img {
    width: 65%;
    height: 90%;
  }
  .container-1340 {
    max-width: 86vw;
    margin: 0 auto;
  }

  .col-lg-5.aboutMacedonia img {
    width: 65%;
    height: 90%;
  }
  span.ALex img {
    width: 80%;
  }
  section.sec-1.hm .aobut-content {
    transform: translateX(-110px);
  }
  .howto-content h2 {
    font-size: 35px;
  }

  .howto-content p {
    font-size: 17px;
  }

  section.sec-2 h1 {
    font-size: 37px;
  }
  .become3 {
    text-align: center;
  }
  footer p {
    font-size: 19px;
  }
  .col-lg-2.social h2 {
    font-size: 23px;
  }
  .col-lg-2.social ul li a {
    font-size: 15px;
  }
  .col-lg-2.social ul:last-child {
    gap: 20px;
    margin-top: 20px;
  }
  .col-lg-2.get-touch h2 {
    font-size: 35px;
  }

  span.links-social a {
    font-size: 12px;
  }
  span.links-social a img {
    width: 17px;
    height: 17px;
  }
  .copy-right-inner a {
    font-size: 11px;
  }
  .copy-right-inner.d-flex {
    gap: 13px;
  }
  .row.let-know p {
    font-size: 18px;
  }
  section.sec-2 {
    padding: 40px 0;
  }
  .let-know p {
    font-size: 18px;
    width: 85%;
    margin: 0 auto;
    margin-top: 25px;
  }
  .container-1050 {
    width: 68vw;
  }
  .sec-contact-title h2 {
    font-size: 32px;
  }
  .sec-contact-title h1 {
    font-size: 45px;
  }
  .banner-contact h2 {
    font-size: 30px;
  }

  .banner-login {
    width: 700px;
    height: 380px;
    border-radius: 12px;
    padding: 0px 50px;
  }

  span.bg-login-logo img {
    width: 140px;
    top: -70px;
  }

  .banner-login h1 {
    font-size: 35px;
  }

  .banner-login [type="radio"]:checked + label,
  .banner-login [type="radio"]:not(:checked) + label {
    font-size: 13px;
  }

  form.check-login {
    height: 39px;
    margin-top: 15px;
  }

  form.input-login input {
    height: 40px;
    font-size: 12px;
  }

  .field-in img {
    width: 16px;
    left: 11px;
    top: 12px;
  }

  form.input-login input {
    padding: 0px 40px;
  }

  .forgerting span p {
    font-size: 13px;
  }

  .forgerting span input {
    width: 16px;
    height: 20px !important;
  }

  .banner-login .banner-btn a {
    width: 120px;
    font-size: 14px;
    height: 37px;
    margin-top: 10px;
  }

  .eye-hide {
    top: 8px;
  }

  .eye-hide svg {
    font-size: 15px;
  }

  span.backtoweb a img {
    width: 70%;
  }

  .login-pg span.sp-ban-2 {
    top: 61px;
    right: -30px;
  }

  .login-pg span.sp-ban-1 {
    bottom: 61px;
    left: -30px;
  }
  .login-pg.register-pg .sec-contact-title h1 {
    font-size: 37px;
  }

  .register-pg .sec-contact-title h2 {
    font-size: 25px;
  }
  .register-pg section.sec-1-contact input,
  label {
    height: 35px;
    font-size: 15px;
  }
  .regiter-log h2 {
    font-size: 20px;
  }
  .regiter-log p {
    font-size: 17px;
  }
  .register-pg span.backtoweb a img {
    width: 77%;
  }
  .register-pg section.sec-1-contact .row {
    padding: 20px 20px;
  }
  .login-pg.Thanks .banner-login {
    height: 340px;
  }
  .Dashboard-left-bar {
    width: 260px;
    margin-left: 30px;
  }
  .Dashboard-righ-side {
    width: calc(100% - 280px);
  }
  .img-upload {
    width: 80px;
    height: 80px;
  }
  .img-upload img {
    width: 103px;
    height: 101px;
  }
  .profile-upload span svg {
    font-size: 14px;
    right: -33px;
  }
  .Dash-menus ul li a {
    font-size: 14px;
  }
  .Dash-menus li.active {
    background: #fddf00;
    border-radius: 11px 0 0 11px;
    padding: 5px 0px;
  }
  .Dash-menus {
    margin-bottom: 8rem;
    margin-top: 3rem;
    width: 83%;
  }
  .dash-title h1 {
    font-size: 46px;
  }
  .ul-btn .banner-btn a {
    font-size: 14px;
    padding: 0 31px;
    height: 40px;
  }
  .row.get-cert label.custom-file-upload.fas .img-upload img {
    width: 60px;
    height: 60px;
  }

  .row.get-cert label.custom-file-upload.fas p {
    font-size: 14px;
  }

  .row.get-cert label.custom-file-upload.fas {
    height: 61%;
  }

  .enter-form label {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .row.get-cert input {
    font-size: 13px;
  }

  .row.get-cert textarea {
    font-size: 13px;
  }

  .enter-form .row.get-cert .banner-btn a {
    font-size: 14px;
    padding: 0 39px;
    height: 40px;
  }
  .enter-form .row.get-cert h2 {
    font-size: 20px;
  }
  .enter-form {
    padding: 20px 20px;
  }
  .enter-form input {
    font-size: 13px;
  }
  .row.notificationpg h2 {
    font-size: 17px;
  }

  .row.notificationpg p {
    font-size: 13px;
    width: 50%;
  }

  .row.notificationpg img {
    width: 78px;
  }
  .enter-form h1 {
    font-size: 38px;
    padding: 10px 0;
  }

  .enter-form h3 {
    font-size: 25px;
  }

  .enter-form h4 {
    font-size: 24px;
  }

  .enter-form.admin-dv {
    padding: 40px 60px 70px 60px;
  }

  .enter-form.admin-dv img {
    width: 74%;
  }

  .row.comments .row.notificationpg img {
    width: 65px;
  }

  .comments .row.notificationpg p {
    font-size: 11px;
  }

  .comments .row.notificationpg h2 {
    font-size: 15px;
  }
  .pagination {
    gap: 11px;
  }
}

@media only screen and (max-width: 1280px) {
  .Dash-menus ul {
    gap: 19px;
  }
  section.sec-1.termscon .aobut-content p {
    width: 80%;
}
select#date {



  width: 65px;

  font-size: 8px;

}
}
