@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
section.Dashboard-citezen {
  display: flex;
}

.Dashboard-left-bar {
  width: 400px;
  margin-left: 50px;
  background-image: url(../images/sidebar.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
  position: fixed;
}

.Dashboard-righ-side {
  width: calc(100% - 450px);
  margin-left: auto;
}
@font-face {
  font-family: "Kiona-Regular";
  src: url(../fonts/Kiona-Regular.ttf);
}
.dash-title h1 {
  color: white;
  font-size: 70px;
  font-family: "Kiona-Regular";
}
.dash-title {
  padding: 30px 3rem;
  border-bottom: 1px solid white;
}
.profile-upload span {
  position: relative;
}

.profile-upload span svg {
  position: absolute;
  right: -52px;
  bottom: 6px;
  font-size: 24px;
  color: #a5a5a5;
}

.profile-upload {
  text-align: center;
}
.profile-upload p {
  color: white;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins";
}
.Dash-logo {
  text-align: center;
}

.Dash-logo img {
  width: 54%;
}
.Dash-menus {
  width: 73%;
  margin-left: auto;
  margin-top: 5rem;
  margin-bottom: 12rem;
}

.Dash-menus ul {
  display: flex;
  gap: 65px;
  flex-direction: column;
}

.Dash-menus ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 6px 40px;
}
.ul-btn .banner-btn a {
  padding: 0 60px;
  border-radius: 12px;
}

.dash-page-con {
  padding: 3rem 3rem;
}

.enter-form input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: none;
  padding: 0 20px;
}
.enter-form {
  background-image: url(../images/bg-dash-pages.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 30px;
  box-shadow: -2px 5px 50px white;
}
.enter-form .banner-btn {
  justify-content: start;

  margin-top: 30px;
}
.enter-form h2 {
  color: white;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
}

.enter-form label {
  color: white;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  display: block;
}
.enter-form span svg {
  position: absolute;
  top: 13px;
  left: 11px !important;
  right: initial !important;
  color: #747472;
}

.enter-form span {
  position: relative;
  display: block;
}
.enter-form span input {
  padding-left: 39px;
}
.row.get-cert label.custom-file-upload.fas {
  height: 80%;
  background: #e6e6e6;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.row.get-cert label.custom-file-upload.fas .img-upload img {
  width: 120px;
  height: 120px;
}

.row.get-cert label.custom-file-upload.fas .img-upload {
  width: 100px;
  height: 100px;
}
.row.get-cert label.custom-file-upload.fas p {
  color: #172e5882;
  font-family: "Poppins";
}
.row.get-cert textarea {
  width: 100%;

  border: none;
  padding: 19px 20px;
  height: 130px;
}
.row.get-cert input {
  margin: 0;
}
.enter-form .row.get-cert h2 {
  font-size: 28px;
  font-weight: 600;
  color: #ffe600;
}

.enter-form .row.get-cert .banner-btn a {
  padding: 0 60px;
}
.download-pdf {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-bottom: 40px;
}
.certicate-editable img {
  width: 100%;
  box-shadow: -2px 5px 50px white;
}

.certificate-sec {
  width: 91%;
}
.row.notificationpg {
  background: #fffbe1;
  border-radius: 17px;
  margin-bottom: 15px;

  padding: 10px 0;
}
.row.notificationpg img {
  width: 100px;
}
.row.notificationpg img {
  width: 100px;
}

.row.notificationpg h2 {
  font-size: 20px;
  font-family: "Poppins";
  color: #3d2c3e;
  font-weight: 500;
}

.row.notificationpg p {
  font-size: 16px;
  font-family: "Poppins";
  color: #3d2c3e;
  font-weight: 500;
  width: 40%;
  margin-bottom: 0;
}

.row.notificationpg span {
  font-size: 14px;
  font-family: "Poppins";
  color: #3d2c3e;
  font-weight: 300;
  width: 40%;
}
.notificationpg .banner-btn {
  justify-content: center !important;
}
span.red-dots {
  background: #ac1111;
  width: 20px !important;
  height: 20px !important;
  display: flex;
  border-radius: 50px;
  position: absolute;
  right: 15px;
  top: 0;
}
.row.notificationpg .col-lg-11 {
  position: relative;
}

.Dash-menus li.active {
  background: #fddf00;
  border-radius: 15px 0 0 15px;
  padding: 8px 0px;
}

.Dash-menus li.active a {
  color: red !important;
  font-weight: 700 !important;
}

.enter-form h3 {
  font-size: 37px;
  color: white;
  padding-bottom: 20px;
}

.enter-form h3 span {
  color: #fee500;
  font-weight: 700;
  display: contents;
}
.enter-form h4 {
  font-size: 37px;
  color: #fee500;
  font-family: "poppins";
  font-weight: 400;
}
.enter-form h1 {
  font-size: 65px;
  color: white;
  font-family: "poppins";
  font-weight: 700;
  padding: 20px 0;
}
.enter-form.admin-dv {
  padding: 70px 60px 160px 60px;
  border-radius: 20px;
}
.comments .row.notificationpg p {
  width: 100% !important;
}

.comments .row.notificationpg .col-lg-2 {
  display: flex;
  align-items: center;
}
.row.comments {
  margin: 0 0;
}
.row.comments .row.notificationpg {
  width: 99%;
  position: relative;
}
.row.comments .total h2 {
  font-weight: bold !important;
  color: red !important;
  text-align: center;
  font-family: "Poppins";
  font-size: 20px;
}
.row.comments .total p {
  font-weight: bold !important;
  color: #3d2c3e !important;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
}
.row.comments .row.notificationpg {
  align-items: center;
}
.row.comments span.red-dots {
  top: 20px;
}
.row.comments select#cars {
  position: absolute;
  right: 40px;
  top: 49%;
  transform: translateY(-50%);
  font-size: 10px;
  font-weight: 500;
  border: 1px solid;
  font-family: "Poppins";
  color: #3d2c3e;
}

/*  */

table {
  border-collapse: collapse;
}
table.simple_zebra th,
td {
  padding: 0.75rem;

  text-align: center;
}

table.simple_zebra thead tr th {
  background: #fddf00;
  font-size: 16px;
  font-family: "Poppins";
  color: #a81010;
  font-weight: 600;
  text-align: center;
}
table.simple_zebra tbody tr td {
  border: 1px solid #707070;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  color: #6d6d6d;
}
tbody tr:nth-child(even) {
  background: #ebebeb;
}
tbody tr:nth-child(odd) {
  background: #ffffff;
}
table.simple_zebra tbody tr td:last-child a {
  padding: 6px 21px;

  font-size: 12px;
  background: #fddf00;
  text-decoration: none;
  color: #a81010;
  border-radius: 20px;
}
table.simple_zebra {
  width: 70%;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 110px;
}

.modal-body {
  background: none !important;
  padding: 0 !important;
}

.modal-body .banner-btn {
  justify-content: end !important;
  margin-top: 50px;
}
table.simple_zebra tbody tr td a {
  text-decoration: none;
  color: #6d6d6d;
}

.pagination {
  display: inline-block;
  gap: 40px;
  width: 70%;
  margin: 0 auto;
  justify-content: end;
  margin-top: 30px;
}
.pagination a {
  text-decoration: none;
  color: #fddf00;
  float: left;
  padding: 8px 16px;
}
.pagination1 a.active {
  background-color: #fddf00;
  color: #a81010;
  border-radius: 50%;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.pagination2 a.active {
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
}
.pagination2 a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
.pagination3 a {
  transition: background-color 0.3s;
}
.pagination4 a:not(.active) {
  border: 1px solid #ddd;
}
.pagination4 a.active {
  border: 1px solid #4caf50;
}
.pagination5 a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination5 a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination6 a {
  margin: 0 4px;
}
.pagination7 a {
  font-size: 22px;
}
.center {
  text-align: center;
}
ul.breadcrumb {
  list-style-type: none;
  background-color: #eee;
  padding: 8px 16px;
}
ul.breadcrumb li {
  display: inline-block;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: #000;
  content: "/\00A0";
}
ul.breadcrumb li a {
  color: green;
}

span.certnme {
  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);
  font-family: "Poppins", sans-serif;

  color: white;
}

.certicate-editable {
  position: relative;
}

span.cetdate {
  position: absolute;
  bottom: 17%;
  left: 52%;
  transform: translateX(-50%);
  color: white;
}
