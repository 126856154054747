html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: Poppins, sans-serif;
}

dl,
ol,
ul {
  margin-top: 0px;
  margin-bottom: 0rem !important;
  padding-left: 0rem !important;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

main {
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "altone-trial";
  src: url(../fonts/altone-trial.regular.ttf);
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

video {
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: 1;
}

/* .overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%);
    background-size: 3px 3px;
    z-index: 2;
  } */

body {
  background-image: url(../images/BG.png);
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.head-menus ul:first-child {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 120px;
  width: 40%;
}

.head-menus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-menus ul li a {
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.head-menus ul:last-child {
  width: 40%;
  gap: 50px;
  display: flex;
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999999999;
}

.head-menus ul:nth-child(2) {
  width: 20%;
  text-align: center;
}

.head-menus ul:last-child li:last-child a {
  color: #ffe600 !important;
  width: 150px;
  height: 50px;
  display: flex;
  border: 2px solid #ffe600;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 600;
}

.banner-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

span.sp-ban-2 {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

span.sp-ban-1 {
  position: absolute;
  left: 10px;
  top: 10px;
}

.banner-title {
  top: 45%;
  position: relative;
  text-align: center;
}

.banner-title h1 {
  font-size: 64px;
  font-weight: 500;
  color: white;
  font-family: "Poppins";
  z-index: 999999;
  position: relative;
}

.banner-title p {
  font-size: 30px;
  color: white;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 17px;
  letter-spacing: 4px;
  text-transform: capitalize;
  z-index: 999999;
  position: relative;
}

.banner-btn {
  display: flex;
  justify-content: center;
  gap: 40px;
}
.banner-btn a:hover {
  background-color: #ffe600;
  box-shadow: -2px 0px 15px white;
  transition: all 0.5s ease-in-out;
  color: red !important;
}
.banner-btn a {
  text-decoration: none;
  color: #ffe600 !important;
  /* width: 150px; */
  height: 50px;
  display: flex;
  border: 2px solid #ffe600;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 600;
  padding: 0 30px;
  position: relative;
  transition: all 0.5s ease-in-out;
}

span.sp-ban-world {
  position: absolute;
  right: 20rem;
  top: 2rem;
}

/* contact page */

.banner-contact {
  width: 30%;
  text-align: left;
  margin: 0 auto;
}

.banner-contact h2 {
  font-size: 36px;
  font-weight: 500;
  color: #fee500;
}

section.sec-1-contact {
  /* height: 100vh; */
  position: relative;
  padding-bottom: 120px;
  padding-top: 50px;
}

.sec-contact-title {
  width: 84%;
  margin-left: auto;
}

.sec-contact-title h1 {
  font-size: 64px;
  font-weight: 500;
  color: white;
  font-family: "Poppins";
}

.sec-contact-title h2 {
  font-size: 50px;
  font-weight: 500;
  color: #fee500;
  margin-bottom: 20px;
}

section.sec-1-contact input,
label {
  width: 100%;
  height: 49px;
  line-height: 93px;
  font-size: 25px;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

section.sec-1-contact textarea {
  width: 100%;
  height: 225px;
}

.form-btm a {
  text-decoration: none;
  color: #ffe600 !important;
  width: 180px;
  height: 50px;
  display: flex;
  border: 2px solid #ffe600;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 40px;
  padding: 0 30px;
}

section.sec-1-contact .row {
  width: 67%;
  margin: 0 auto;
  background-image: url(../images/contact-formbg.png);
  padding: 50px 20px;
  background-position: center;
  background-size: cover;
  box-shadow: -2px 5px 50px white;
}

span.links-social a {
  display: flex;
  gap: 10px;
  margin-bottom: 28px;
  color: white;
  text-decoration: none;

  font-size: 20px;
  font-family: "altone-trial";
}

.col-lg-4.social-icons h2 {
  font-size: 50px;
  font-weight: 500;
  color: #fee500;
  font-family: "Bebas Neue", sans-serif;
}

.col-lg-4.social-icons {
  padding-left: 30px;
}

.col-lg-4.social-icons > img {
  width: 62%;
}

.container-1600 {
  width: 1600px;
  margin: 0 auto;
}

footer .col-lg-8 {
  text-align: center;
}

footer p {
  font-size: 30px;
  color: white;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 17px;
  letter-spacing: 4px;
  text-transform: capitalize;
}

.col-lg-2.get-touch h2 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #fee500;
  font-family: "Bebas Neue", sans-serif;
}

.col-lg-2.social h2 {
  font-size: 33.33px;
  color: white;
}

.col-lg-2.social ul li a {
  color: white;
  text-decoration: none;
  margin-bottom: 20px;
  display: block;
  font-size: 20.83px;
}

.col-lg-3.social h2:last-child {
  margin-top: 30px !important;
}

.col-lg-2.social h2:nth-child(3) {
  margin-top: 60px;
}

.col-lg-2.social ul:last-child {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

footer {
  padding: 120px 0px;
  background-image: url(../images/footer-bg.png);
  background-position: center;
  background-size: 100%, 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-bottom p {
  text-align: center;
  color: white;
  margin: 0px;
  font-size: 20px;
}

.copy-right {
  background: #c53032;
  margin: 0px;
  padding: 20px 0px;
}
.copy-right .container-1600 {
  display: flex;
  justify-content: space-between;
}
.copy-right-inner a {
  text-decoration: none;
  color: white;
}

.copy-right-inner.d-flex {
  gap: 30px;
}

.foot-content ul li a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.copy-right ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.foot-content h2 {
  color: white;
  font-size: 32px;
  font-weight: 500;
  font-family: Poppins;
}
.copy-right-inner p {
  color: white;
  font-size: 13px;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
.copy-right-inner.d-flex span {
  padding-right: 20px;
}
.col-lg-2.social.m-auto {
  border-left: 1px solid white;
  padding-left: 26px;
}

.head-menus ul li {
  position: relative;
}
/* login page */

form.check-login {
  gap: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: -24px;
  margin-top: 27px;
}

form.check-login span {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

form.check-login span label {
  font-size: 16px;
  font-family: "Poppins";
}

form.check-login span input {
  accent-color: white;
}

.banner-login [type="radio"]:checked,
.banner-login [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.banner-login [type="radio"]:checked + label,
.banner-login [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.banner-login [type="radio"]:checked + label:before,
.banner-login [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.banner-login [type="radio"]:checked + label:after,
.banner-login [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: red;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.banner-login [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.banner-login [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
form.input-login input {
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 60px;
}

.forgerting {
  display: flex;
  justify-content: space-between;
}

.forgerting span {
  display: flex;
  align-items: center;
  gap: 13px;
}

.forgerting span p {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0;
  color: white;
}

.forgerting span input {
  width: 24px;
  height: 30px;
  margin: 0;
  accent-color: red;
}

.banner-login {
  position: absolute;
  width: 900px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background-image: url(../images/loginbg.png);
  background-position: 100%, 100%;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  padding: 20px 110px;
  box-shadow: -2px 5px 50px white;
  height: 500px;
}
span.bg-login-logo img {
  position: absolute;
  width: 200px;
  left: 50%;
  top: -100px;
  transform: translateX(-50%);
}
.banner-login h1 {
  font-family: "Poppins";
  font-size: 54px;
  font-weight: 600;
  color: white;
}
p.welcom {
  color: #fee500;
  font-size: 20px;
  font-family: "Poppins";
}
.banner-login .banner-btn a {
  width: 200px;
  margin-top: 20px;
  font-size: 20px;
}
.field-in {
  position: relative;
}
.login-pg .row {
  position: relative;
  top: 70px;
}
.field-in img {
  position: absolute;
  left: 21px;
  top: 13px;
}

/*  */
.login-pg span.sp-ban-1 {
  top: initial;
  bottom: 20px;
  transform: rotate(-90deg);
}

.login-pg span.sp-ban-2 {
  top: 20px;
  transform: rotate(-90deg);
  bottom: inherit;
}
span.backtoweb {
  position: absolute;
  left: 10px;
  top: 10px;
}

.toggle-password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-password:hover {
  opacity: 0.7;
}
.forgerting span p a {
  color: white;
  text-decoration: none;
}
.login-pg.forget .forgerting span a {
  color: #ffe600 !important;
  text-decoration: none;
}

.login-pg.forget .forgerting span a p {
  color: #ffe600 !important;
  font-weight: bold;
}
.login-pg.forget p.welcom {
  margin: 29px 0;
}

/*  */
.login-pg.veriifcation-pg .field-in {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.login-pg.veriifcation-pg .field-in input {
  width: 80px !important;
  display: flex;
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: red;
  font-family: "Poppins";
  padding: 0 22px;
  width: 80px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.eye-hide {
  position: absolute;
  right: 14px;
  top: 14px;
}

.eye-hide svg {
  color: #817e7e;
  font-size: 22px;
}
form.input-login input::placeholder {
  color: #cb8787;
}
.banner-btn.Done a {
  background: #fee500;
  color: red !important;
  box-shadow: -2px 5px 50px white;
}

/*  */
.row.under-row {
  width: 100% !important;
  background-image: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  position: relative;
  top: 0px;
}
.row.under-row .banner-btn {
  justify-content: start;
  margin-top: 20px;
}

.row.under-row .banner-btn a {
  padding: 0 60px;
}
.row.under-row .forgerting {
  margin-top: 19px;
}
.row.under-row section.sec-1-contact input,
label {
  line-height: 50px;
}
.regiter-log h2 {
  font-size: 32px;
  color: white;
  font-family: "Poppins";
  text-align: center;
  font-weight: 600;
}
section.sec-1-contact input {
  color: black;
  font-size: 18px;
  padding: 0 10px;
}
.regiter-log {
  text-align: center;
}

.regiter-log p {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.regiter-log img {
  width: 56%;
}
.register-pg section.sec-1-contact .row {
  background-image: url(../images/register.png);
}
.register-pg .sec-contact-title h2 {
  font-size: 35px;
}

.register-pg span.backtoweb {
  right: 2rem;
  left: initial;
  top: initial;
  bottom: 4rem;
}

.register-pg span.backtoweb a img {
  width: 90%;
}

/* thanks page */

.login-pg.Thanks .banner-login {
  height: 400px;
  border-radius: 13px;
  display: flex;
  /* padding-top: 70px; */
  padding: 0 0;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
}

.login-pg.Thanks .banner-login h2 {
  color: white;
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 600;
}

.login-pg.Thanks .banner-login p {
  color: white;
  font-size: 20px;
  font-weight: 400;
}
/*  */
section.sec-1 {
  position: relative;
  padding: 100px 0;
  height: 100vh;
}
.container-1340 {
  max-width: 1340px;
  margin: 0 auto;
}
.aobut-content h2 {
  font-size: 50px;
  color: white;
  font-weight: 600;
}

.aobut-content h2 span {
  font-weight: 400;
  color: #fee500;
}

.aobut-content p {
  color: #fee500;
  font-size: 25px;
  font-weight: 300;
}
.aobut-content {
  width: 116%;
  position: relative;
}

.aobut-content .banner-btn {
  justify-content: start;
}
.col-lg-5.aboutMacedonia {
  text-align: end;
  transform: translateX(90px);
}

section.sec-2 {
  position: relative;
  padding: 100px 0;
}
section.sec-2 h1 {
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 70px;
}

section.sec-2 h1 span {
  color: #fee500;
  font-weight: 500;
}

.howto-content h2 {
  font-size: 50px;
  color: white;
  font-weight: 600;
}

.howto-content p {
  color: #fee500;
  font-size: 25px;
  font-weight: 300;
}
.container-1050 {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}
span.ALex {
  position: absolute;
  right: 0;
  z-index: -1;
}

section.sec-1 .col-lg-6 {
  position: relative;
}

section.sec-2 .col-lg-7.pb-5 {
  position: relative;
}

section.sec-2 .col-lg-7 {
  position: relative;
}
.let-know {
  display: flex;
  justify-content: space-around;
}
.row.let-know p {
  color: #fee500;
  font-size: 25px;
  font-weight: 300;
  margin-top: 30px;
  text-align: center;
}

.img-wrap {
  position: relative;
  display: inline-block;
}

.img-upload {
  width: 130px;
  height: 130px;

  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.img-upload img {
  width: 153px;
  height: 151px;
}
.rounded-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
label.custom-file-upload.fas input {
  display: none;
}

/* menus hover */

.head-menus ul li {
  list-style: none;
}
.head-menus ul li a {
  position: relative;
  /* display: block; */
  /* text-transform: uppercase; */
  /* margin: 20px 0; */
  padding: 10px 20px;
  /* text-decoration: none; */
  /* color: #262626; */
  /* font-family: sans-serif; */
  /* font-size: 18px; */
  /* font-weight: 600; */
  transition: 0.5s;
  z-index: 1;
}
.head-menus ul li a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid #ffe600;
  border-bottom: 2px solid #ffe600;
  transform: scaleY(2);
  opacity: 0;
  transition: 0.3s;
}
.head-menus ul li a:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #490100;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  z-index: -1;
}
.head-menus ul li a:hover {
  color: #fff;
}
.head-menus ul li a:hover:before {
  transform: scaleY(1);
  opacity: 1;
}
.head-menus ul li a:hover:after {
  transform: scaleY(1);
  opacity: 1;
}
.head-menus ul.child-ul-2 li:nth-child(1) a:before {
  display: none;
}

.head-menus ul.child-ul-2 li:nth-child(1) a:after {
  display: none;
}
.head-menus ul.child-ul-2 li:nth-child(1) a {
  padding: 0;
}

.head-menus ul.child-ul-3 li:last-child a:before {
  display: none;
}

.head-menus ul.child-ul-3 li:last-child a:after {
  display: none;
}
ul.child-ul-3 {
  align-items: center;
}

.head-menus li.active a {
  color: #fee500 !important;
  font-weight: bold;
}
.head-menus ul:last-child li:last-child a:hover {
  background-color: #ffe600;
  box-shadow: -2px 0px 15px white;
  transition: all 0.5s ease-in-out;
  color: red !important;
}
.col-lg-2.social ul li a:hover {
  color: #ffe600;
}
.col-lg-2.social ul li a {
  transition: all 0.5s ease-in-out;
}
ul.icons-soc li a img {
  transition: all 0.5s ease-in-out;
}

ul.icons-soc li a img:hover {
  cursor: pointer;
  -webkit-transform: translateX(-10px);
  transform: translatey(-10px);
  transition: all 0.5s ease-in-out;
}

.back-btn {
  display: flex;
  justify-content: space-between;
}

.ourbox {
  background: #fee500;
  border-radius: 0px 0px 13px 13px;
  padding-bottom: 13px;
  text-align: center;
  box-shadow: -2px 0px 15px #433e3e;
  width: 260px;
  height: 160px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.ourbox h2 {
  font-size: 26px;
  font-family: "Poppins";
  color: #ffffff;
  background: #a81818;
  padding: 6px 3px;
}
.ourbox p {
  color: #a81818;
  font-family: "Poppins";
  font-size: 20px;
  padding-top: 14px;
  padding-top: 6px;
}

section.sec-extra {
  background: #e0a507;
  height: 300px;
  padding-top: 40px;
}

.meanbox {
  display: flex;
  gap: 50px;
  justify-content: center;

  width: 87%;
  margin: 0 auto;
}
.ourbox p span {
  display: none;
}

.ourbox:hover p span {
  display: block;
}
.ourbox:hover {
  height: 243px;
}
.aobut-content.ab p {
  width: 90%;
}

/*  */

.progress-bar {
  position: relative;
  height: 20px;
  width: 200px;
  background: white;
  border-radius: 25px;
  border: 2px solid white;

  box-shadow: 0 0 32px white;
}
.pro-br {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
}
.progress-fill {
  position: absolute;
  height: 20px;
  width: 0%;
  animation: progress-forward 4s infinite;
  background: rgb(34, 193, 195);
  background: linear-gradient(90deg, #dc3545 0%, #dc3545 100%);
  border-radius: 15px;
}

@keyframes progress-forward {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}

.form-btm a {
  transition: all 0.5s ease-in-out;
}
.form-btm a:hover {
  background-color: #ffe600;
  box-shadow: -2px 0px 15px white;
  transition: all 0.5s ease-in-out;
  color: red !important;
}
.enter-form select {
  word-wrap: normal;
  width: 100%;
  border: none;
  padding: 9px 20px;
  height: 40px;
  color: #75757e;
}
.enter-form select::placeholder {
  color: #75757e;
}
.set-img img {
  width: 50%;
  height: 100%;
}

select#date {
  top: 50%;
  position: absolute;
  right: 50px;
  width: 61px;

  left: initial;

  width: 80px;
  background: #fffbe1;
  border: 1px solid;
  color: #3d2c3e;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;

  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.progress-bar {
  position: relative;
  height: 20px;
  width: 200px;
  background: white;
  border-radius: 25px;
  border: 2px solid white;

  box-shadow: 0 0 32px white;
}

span.links-social a img {
  width: 32px;
  height: 39px;
}

button#rswp-card-button {
  background: #fddf00;
  width: 30%;
  margin: 0 auto;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
span.sq-card-message.sq-card-message-no-error {
  color: white;
}
.sq-card-wrapper .sq-card-message-error {
  color: #fddf00 !important;
}
.nonekar {
  opacity: 0;
}

h2.getpay {
  display: flex;
  font-size: 12px;
  gap: 11px;
}
.dash-title.forcerti {
  text-align: center;
}

.dash-title.forcerti span {
  color: #fddf00 !important;
  font-style: initial;
}

div#google_translate_element {
  position: absolute;
  right: 67px;
  top: 14px;
}

div#google_translate_element span {
  display: none;
}

div#\:0\.targetLanguage {
  opacity: 1 !important;
  position: absolute;
  right: 1px;
}
select.goog-te-combo {
  height: 32px;
  background: #910d0b;
  border: none;
  color: white;
}
